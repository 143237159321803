type SearchBadge = {
    key: string;
};

export const SITE_SELECTOR_HERO_BADGES: SearchBadge[] = [
    {
        key: 'homepage.knowledge-agent-search.suggestion-domain',
    },
    {
        key: 'homepage.knowledge-agent-search.suggestion-premium',
    },
    {
        key: 'homepage.knowledge-agent-search.suggestion-dynamic',
    },
    {
        key: 'homepage.knowledge-agent-search.suggestion-ads',
    },
    {
        key: 'homepage.knowledge-agent-search.suggestion-email',
    }
];

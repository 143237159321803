import { Article, CategoryArticles } from '@wix/answers-api';
import { Box } from '@wix/design-system';
import classNames from 'classnames';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useContext, useMemo, useRef } from 'react';

import { Blogs } from '../components/Blogs';
import { ExploreMoreResources } from '../components/ExploreMoreResources';
import { Hero } from '../components/Hero';
import { KnowledgeAgentSearchHero } from '../components/KnowledgeAgentSearchHero';
import { MarketplaceEntry } from '../components/MarketplaceEntry';
import { RoadmapBanner } from '../components/RoadmapBanner';
import { SiteSelectorHero } from '../components/SiteSelectorHero';
import { StudioCommunityForumEntry } from '../components/StudioCommunityForumEntry';
import { TopicsSection } from '../components/TopicsSection';
import { TrendingArticles } from '../components/TrendingArticles';
import { LOCALES } from '../constants';
import { Context } from '../context';
import { DATA_HOOKS } from '../dataHooks';
import { useIsKnowledgeAgentSearchEnabled } from '../hooks/useIsKnowledgeAgentSearchEnabled';
import { useIsSitePickerEnabled } from '../hooks/useIsSitePickerEnabled';
import { getHelpCenterData, getPageSeo } from '../services/answers';
import { fetchBlogs } from '../services/wix-blogs';
import { KbBlog, PageType } from '../types';
import { withServerSideProps } from '../utils/with-server-side-props';
import { useStore } from '../zustand/stores';

import css from './HomePage.module.scss';

export type HomePageProps = {
  categoriesTopArticles: CategoryArticles[];
  trendingArticles: Article[];
  blogs: KbBlog[];
};

const HomePage: NextPage<HomePageProps> = ({ trendingArticles, blogs }) => {
  const categoriesTree = useStore(
    (state) => state.categoriesTreeSlice.categoriesTree
  );
  const { locale } = useRouter();
  const { isWixStudioUser } = useContext(Context);
  const contentRef = useRef(null);
  const isKnowledgeAgentSearchEnabled = useIsKnowledgeAgentSearchEnabled();
  const isSiteSelector = useIsSitePickerEnabled();

  const scrollIntoViewIfNeeded = () => {
    if (contentRef.current) {
      const focusedItem = contentRef.current as HTMLElement;
      if (focusedItem) {
        const offset = 70;
        const top =
          focusedItem.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({ top, behavior: 'smooth' });
      }
    }
  };

  const HeroComponent = useMemo(() => {
    if (isSiteSelector) {
      return <SiteSelectorHero onScrollDown={scrollIntoViewIfNeeded} />;
    }
    if (isKnowledgeAgentSearchEnabled) {
      return <KnowledgeAgentSearchHero onScrollDown={scrollIntoViewIfNeeded} />;
    }

    return <Hero />;
  }, [isSiteSelector, isKnowledgeAgentSearchEnabled]);

  return (
    <Box
      direction="vertical"
      className={`${css.wrapper} ${css.newHomepage}`}
      dataHook={DATA_HOOKS.HOME_PAGE}
    >
      {HeroComponent}
      <div
        ref={contentRef}
        className={classNames({
          [css.contentWrapper]: isKnowledgeAgentSearchEnabled,
          [css.siteSelector]: isSiteSelector,
        })}
      >
        {!isSiteSelector && <TrendingArticles articles={trendingArticles} />}
        <TopicsSection topics={categoriesTree} />
        <RoadmapBanner />
        {blogs.length > 0 && <Blogs blogs={blogs} />}
        <ExploreMoreResources
          isShowBlogResource={!blogs.length}
          isNonEnglishLocale={locale !== LOCALES.EN}
        />
        {isWixStudioUser ? <StudioCommunityForumEntry /> : <MarketplaceEntry />}
      </div>
    </Box>
  );
};

export const getServerSideProps = withServerSideProps(
  async ({ req, res, locale }) => {
    const { categories: categoriesTree, articles: globalTrendingArticles } =
      await getHelpCenterData(req.aspects, locale, res.locals.redisClient);

    const pageSeo = await getPageSeo(
      req.aspects,
      locale,
      PageType.Homepage,
      res.locals.redisClient
    );

    const blogs = await fetchBlogs(
      res.locals.appContext,
      req,
      locale,
      res.locals.redisClient
    );

    const trendingArticles = globalTrendingArticles;

    return {
      props: {
        pageType: PageType.Homepage,
        categoriesTree,
        pageSeo,
        trendingArticles,
        blogs,
        pageData: {},
      },
    };
  }
);

export default HomePage;

import { Box, Button, Heading, IconButton, Image, Input, Tag, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import { FunctionComponent, KeyboardEvent, MouseEvent, useCallback, useContext, useState } from 'react';
import { } from 'wix-ui-icons-common';
import { Context } from '../../context';
import { DATA_HOOKS } from '../../dataHooks';
import { useGoToSearchResultsPage } from '../../hooks/useGoToSearchResultsPage';
import { PageType } from '../../types';
import { InnerLink } from '../InnerLink';
import { RenderHTML } from '../RenderHTML';
import { SiteSelector, SiteSelectorParent } from '../SiteSelector';
import { SITE_SELECTOR_HERO_BADGES } from './badges';
import { useSiteSelectorHeroBI } from './bi';
import css from './index.module.scss';
import { MAX_SEARCH_INPUT_LENGTH } from '../KnowledgeAgentSearchHero';
import { useIsGenAnswer } from '../../hooks/useIsGenAnswer';
import { SparklesFilled as SparklesFilledIcon, ChevronDownSmall as ChevronDownIcon, Search as SearchIcon, ArrowRight as ArrowRightIcon } from '@wix/wix-ui-icons-common';

export type SiteSelectorHeroProps = {
  onScrollDown: () => void;
};

type BadgeClickEventType = KeyboardEvent<Element> | MouseEvent<HTMLAnchorElement, globalThis.MouseEvent> | MouseEvent<Element, globalThis.MouseEvent>;

export const SiteSelectorHero: FunctionComponent<SiteSelectorHeroProps> = ({ onScrollDown }) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const { user, siteSelectorData } = useContext(Context);
  const { locale } = useRouter();
  const { onScrollDownEvent } = useSiteSelectorHeroBI(locale as string);
  const { goToSearchResultsPage } = useGoToSearchResultsPage();
  const [query, setQuery] = useState<string>('');

  const onScrollDownPage = useCallback(() => {
    void onScrollDownEvent();
    onScrollDown();
  }, [onScrollDown, onScrollDownEvent]);

  const isGenAnswerEnabled = useIsGenAnswer();

  const goToSearchResultsPageWrapper = async (e: BadgeClickEventType, overrideQuery?: string) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (query || overrideQuery) {
      await goToSearchResultsPage({
        term: query,
        previousPageType: PageType.Homepage,
        overrideTerm: overrideQuery,
      });
    }
  };

  const hasSites = !!siteSelectorData.itemsCount;

  const getSubtitle = () => {
    if (!context.isLoggedInUser) {
      return t('site-selector.subtitle-guest');
    }
    if (!hasSites) {
      return t('site-selector.no-sites.subtitle');
    }
    return t('site-selector.subtitle');
  };

  return (
    <Box className={css.wrapper} direction="horizontal" verticalAlign="middle" width='100%'>
      <Box className={css.innerWrapper} direction="vertical" verticalAlign="middle" align="center" margin='83px' width='100%'>
        <Box className={css.leftAndRightSide} direction="horizontal" verticalAlign="middle" align="space-between" flex={1} gap={14}>
          <Box className={css.leftSide} direction="vertical" maxWidth={772}>
            <Heading size='large' className={css.title} dataHook={DATA_HOOKS.SITE_SELECTOR_HERO_TITLE}>
              {context.isLoggedInUser && user?.firstName ? <RenderHTML html={t('site-selector.personalized-title', { firstName: user?.firstName })} />
                : t('site-selector.title')}
            </Heading>
            <Heading size='small' className={css.subtitle} dataHook={DATA_HOOKS.SITE_SELECTOR_HERO_SUBTITLE}>
              {getSubtitle()}
              <SiteSelector parent={SiteSelectorParent.Hero} />
            </Heading>
            <Box direction="horizontal" className={css.searchBar}>
              <Input
                size="medium"
                autoFocus={true}
                maxLength={MAX_SEARCH_INPUT_LENGTH}
                dataHook={DATA_HOOKS.HOME_SEARCH_INPUT}
                className={css.searchInput}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t('site-selector.search-placeholder')}
                prefix={
                  <Input.IconAffix>
                    {isGenAnswerEnabled ? (
                      <SparklesFilledIcon />
                    ) :
                      <SearchIcon />
                    }
                  </Input.IconAffix>
                }
                suffix={
                  isGenAnswerEnabled ? (
                    <Input.IconAffix>
                      <Button
                        className={css.searchIconAI}
                        size="medium"
                        skin="ai"
                        dataHook={DATA_HOOKS.HERO_SEARCH_BUTTON}
                        onClick={goToSearchResultsPageWrapper}
                      >
                        {t('site-selector.search.button.gen-answer')}
                      </Button>
                    </Input.IconAffix>
                  ) : (
                    <Input.IconAffix>
                      <IconButton
                        className={css.searchArrowIconAI}
                        size="large"
                        skin="standard"
                        priority="primary"
                        dataHook={DATA_HOOKS.HERO_SEARCH_BUTTON}
                        onClick={goToSearchResultsPageWrapper}
                      >
                        <ArrowRightIcon />
                      </IconButton>
                    </Input.IconAffix>
                  )
                }
                onEnterPressed={goToSearchResultsPageWrapper}
                value={query}
              />
            </Box>
            <Box className={css.badges} flexWrap='wrap' gap={1}>
              {SITE_SELECTOR_HERO_BADGES.map((badge) => (
                <InnerLink key={badge.key} href={`/search?term=${t(badge.key)}`} className={css.badgeLink} onClick={(e) => goToSearchResultsPageWrapper(e, t(badge.key))}>
                  <Tag id={badge.key} size='medium' className={css.badge} removable={false} theme='lightOutlined' dataHook={DATA_HOOKS.SITE_SELECTOR_HERO_BADGE}>
                    <Text size='tiny' weight='thin' className={css.badgeText}>
                      {t(badge.key)}
                    </Text>
                  </Tag>
                </InnerLink>
              ))}
            </Box>
          </Box>
          <Box className={css.rightSide}>
            <Image
              src='https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/trending-articles/trending-articles-new.jpg'
              alt={t('homepage.trending-articles.background.alt')}
              borderRadius={30}
              className={css.heroImage}
            />
          </Box>
        </Box>
        <TextButton
          dataHook={DATA_HOOKS.SCROLL_DOWN_ARROW}
          className={css.exploreMoreContainer}
          aria-label={t('homepage.knowledge-agent-search.explore-more')}
          onClick={onScrollDownPage}>
          <Box className={css.exploreMore} direction="vertical" verticalAlign="middle" align="center">
            <Text skin="primary" className={css.exploreMoreText} ellipsis maxWidth={100}>
              {t('homepage.knowledge-agent-search.explore-more')}
            </Text>
            <Box className={css.exploreMoreArrow}>
              <ChevronDownIcon className={css.exploreMoreIcon} />
            </Box>
          </Box>
        </TextButton>
      </Box>
    </Box >
  );
};
